<template>
  <simpleDialog :dialog.sync="dialogLoading">
    <v-card class="text-center">
      <v-card-text class="pa-5 black--text subtitle-1">
        <v-icon color="primary" class="fa-pulse" large >fas fa-spinner</v-icon>
        <br />
        <br />
        Aguarde um momento
      </v-card-text>
    </v-card>
  </simpleDialog>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import simpleDialog from "./../../components/structure/simpleDialog.vue";

export default {
  methods: {
    ...mapMutations(["toogleDialogLoading"])
  },
  computed: {
    ...mapState(["app"]),
    dialogLoading: {
      get: function() {
        return this.app.dialogLoading;
      },
      set: function(value) {
        this.toogleDialogLoading(value);
      }
    }
  },
  watch: {
    $route() {
      if (this.dialogLoading) this.dialogLoading = false;
    }
  },
  components: {
    simpleDialog
  }
};
</script>
