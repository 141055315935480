import $util from '../../../common/util'
import $auth from '../../../common/auth'

const getMenus = () => {
  return [{
      // icon: "fas fa-shopping-cart",
      // badge: "carrinho"
      icon: " ",
      text: " ",
      id: 'eu-protegido-market-place-7'
    },
    // {
    //   icon: "fas fa-comment",
    //   link: "atendimentos",
    //   text: "Chamados",
    //   showifauth: true
    // },
    // /img/icons/home/logo-icon.svg
    {
      icon: "img/app-logo.png",
      link: "root",
      text: "Eu Protegido",
      large: true,
      id: 'eu-protegido-umbrella',
    },
    {
      icon: "/img/icons/home/user-icon.svg",
      link: "opcoes",
      text: "Perfil",
      id: 'eu-protegido-option',
      action: 'abrir'
    },
  ];
}

const getIndexCurrentMenu = () => {
  return getMenus().findIndex(element => {
    var ismenu = $util.vue().$route.name === element.link;
    if (!ismenu) {
      if ($util.vue().$route.meta.bch && $util.vue().$route.meta.bch.length > 0) {
        // eslint-disable-next-line
        for (const [bchindex, bchitem] of $util.vue().$route.meta.bch.entries()) {
          // eslint-disable-next-line no-unused-vars
          if (element.link === bchitem) {
            ismenu = true;
            break;
          }
        }
      }
    }
    return ismenu;
  });
}

const isItemVisible = (item) => {
  if (item.showifsf != undefined && item.showifsf)
    return $auth.userIsSF()
  else if (item.showifauth != undefined && item.showifauth)
    return $auth.isAuthenticated()
  else return true
}
const menuUtil = {
  getMenus,
  getIndexCurrentMenu,
  isItemVisible
}

export {
  menuUtil
}
