<template>
  <v-row class="pa-0 ma-0" id="screenshot">
    <v-col cols="12" class="pa-0 ">
      <!-- scroll only container -->
      <v-style v-if="isAuth">
        #mainView{
          height: calc(100vh - 60px) !important;
          padding-bottom: 12px !important;
        }
        .fitContainer{
          height: calc(100vh - {{app.structure.heightBottom}}{{menu.toolbar ? ' - '+app.structure.heightToolbar : ''}}{{menu.toolbarBehavior ? ' - '+app.structure.heightToolbar : ''}}-30) !important;
        }
      </v-style>
      <v-style v-else>
        #mainView{
          height: 100vh;
        }
      </v-style>

      <genericDialog
        :dialog-on="connectCompanyDialog.dialogOn"
        :bg-image="connectCompanyDialog.bgImage"
        :btn-text="connectCompanyDialog.btnText"
        :dialog-text="connectCompanyDialog.dialogText"
        :dialog-title="connectCompanyDialog.dialogTitle"
        :toggle-dialog="toggleConectCompanyDialog"
        :btn-action="conectCompanyDialogAction"
      />
      <toolbar v-if="isAuth"/>
      <main-view/>
      <menuMobile v-if="isAuth"/>
    </v-col>
  </v-row>
</template>

<script>
import toolbar from "./../../components/structure/toolbar.vue";
import mainView from "./mainView.vue";
import menuMobile from "./../../components/structure/menu/menuMobile.vue";
import genericDialog from "./../../components/generic-dialog/index.vue";
import { mapState } from "vuex";

export default {
  components: {
    toolbar,
    mainView,
    menuMobile,
    genericDialog
  },
  data() {
    return {
      isAuth: false,
      connectCompanyDialog: {
        dialogOn: false,
        bgImage: '/img/bg-dialogs/bg-dialog-conecte-empresa.png',
        dialogTitle: 'Conecte o Eu protegido com a sua empresa',
        dialogText: 'Assim você pode utilizar todas as facilidades do app.',
        btnText: 'Conectar',
      },
    };
  },
  watch: {
    $route() {
      this.isAuth = this.$auth.isAuthenticated();
    },
  },
  computed: {
    ...mapState(["app", "menu"])
  },
  methods: {
    toggleConectCompanyDialog() {
      this.connectCompanyDialog.dialogOn = !this.connectCompanyDialog.dialogOn;
    },
    conectCompanyDialogAction() {
      this.$router.push('/conectarComSuaEmpresa');
      this.toggleConectCompanyDialog();
    },
  },
  mounted() {
    this.isAuth = this.$auth.isAuthenticated();
  }
};
</script>

<style>
html,
body,
div.application,
div.application--wrap,
div.v-content__wrap,
main {
  background: rgba(255,255,255,.3) !important;
  mix-blend-mode: normal !important;
  padding: 0 !important;
  margin: 0 !important;
}
#mainView,
.fitContainer {
  width: 100% !important;
  padding: 0 !important;
  margin: 0 !important;
}
</style>