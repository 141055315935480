<template>
<div id="MenuBottom" style="width: 100%;" :key="app.reloadableKey">
    <v-bottom-navigation
      app
      grow
      v-model="activeBtn"
      :value="true"
      :mandatory="false"
      background-color="appbgcolor"
      id="menuFooter"
    >
      <template v-for="(item, index) in items">
        <v-btn
          v-show="menuUtil.isItemVisible(item)"
          @click="() => { if(!app.loading) redirect(item) }"
          :key="index"
          :id="item.id"
          :class="item.text === 'Market Place' ? 'menu-opacity' : ''"
        >
          <span
            :style="
              item.text === 'Eu Protegido' ?
              appStyle : { 'font-size': '11px !important' }
            "
            :class="(activeBtn === index ? 'tertiary' : 'white') + '--text'"
            v-html="item.text"
          ></span>
          <v-badge v-if="item.text === 'Nick'" color="tertiary" content="9">
            <menuIcon :item="item" :active="activeBtn === index" />
          </v-badge>
          <menuIcon v-else :item="item" :active="activeBtn === index" />
        </v-btn>
      </template>
    </v-bottom-navigation>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import { menuUtil } from "./menuUtil";
import menuIcon from "./menuIcon.vue";

export default {
  data: () => ({
    activeBtn: undefined,
    items: [],
    rotaAtual: window.location.href,
    appStyle: {
      'font-size': '11px !important',
      color: '#50E4DE !important',
      position: 'absolute',
      bottom: '15px'
    }
  }),
  mounted() {
    this.loadItens();
    this.selectCurrent();
  },
  watch: {
    $route() {
      this.selectCurrent();
    },
  },
  computed: {
    ...mapState(["app", "menu"]),
    menuUtil: () => menuUtil,
  },
  methods: {
    ...mapMutations(["toggleShowHamgurber"]),
    redirect(item) {
      if (!item.action) {
        if(this.rotaAtual != this.$util.link(item.link)) {
          this.$util.link(item.link);
        }

      } else {
        window.scrollTo(0,0);
        this.toggleShowHamgurber({ situacao: true });
      }
    },
    loadItens() {
      this.items = menuUtil.getMenus();
    },
    selectCurrent() {
      var index = menuUtil.getIndexCurrentMenu();
      /*if (index == -1) {
        index = 1;
      }*/

      if (index >= 0 && index != undefined) {
        this.activeBtn = index;
      } else {
        this.activeBtn = undefined;
      }
    },
  },
  components: {
    menuIcon,
  },
};
</script>

<style>
#menuFooter {
  height: var(--v-heightBottom) !important;
  padding-bottom: var(--v-paddingBottom) !important;
}
#menuFooter .v-badge__badge {
  height: initial !important;
  width: initial !important;
  min-width: initial !important;
}

#menuFooter.v-item-group.v-bottom-navigation .v-btn {
  min-width: 76px;
}

.menu-opacity {
  opacity: 0.5;
}
</style>
