<template>
  <div id="mainView">
    <loadingDialog />
    <span v-show="app.mainView">
      <keep-alive>
        <router-view name="keepalive"></router-view>
      </keep-alive>
      <router-view></router-view>
    </span>
  </div>
</template>

<script>
import {mapState} from "vuex";
import loadingDialog from "./loadingDialog.vue";

export default {
  computed: {
    ...mapState(["app", "menu"])
  },
  components: {
    loadingDialog
  },
};
</script>