<template>
  <span id="menuIcons" :key="app.reloadableKey">
    <v-badge left v-if="item.badge && badges[item.badge]" overlap color="red">
      <!-- <span slot="badge">{{badges[item.badge]}}</span> -->
      <span slot="badge"></span>
      <v-icon :class="iconClass" :color="iconColor">{{item.icon}}</v-icon>
    </v-badge>
    <template v-else>
      <v-card style="background-color: transparent; position: center;" elevation="0">
        <img :style="item.id === appId ? { paddingBottom: '90%' } : null" :src="item.icon">
      </v-card>
    </template>
  </span>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      appId: 'eu-protegido-umbrella'
    }
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    active: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    ...mapState(["badges", "app"]),
    iconClass() {
      return (
        (this.$util.isMobile() ? " isMobile" : "") + (this.item.large ? " iconLarge" : "")
      );
    },
    iconColor() {
      return this.active ? "secondary" : "white";
    }
  }
};
</script>

<style scoped>
/* icons size */
#menuIcons .v-icon {
  font-size: 18px !important;
  margin-bottom: 4px !important;
}
#menuIcons .v-icon.iconLarge {
  font-size: 28px !important;
}
#menuIcons .v-icon.iconLarge.isMobile {
  margin-top: -8px !important;
}
</style>
