<template>
  <v-dialog v-model="show" content-class="simpleDialog" persistent>
    <slot />
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    show: {
      get: function() {
        return this.dialog;
      },
      set: function(value) {
        this.$emit("update:dialog", value);
      }
    }
  }
};
</script>

<style>
div.simpleDialog.v-dialog {
  width: auto !important;
}
</style>